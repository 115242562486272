body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
    margin: 1.75rem auto;
  }
  .modal-content {
    width: 80% !important;
    left: 10%;
  }
}

@media screen and (max-width: 799px) {
  body {
    background-color: white !important;
  }
}
