.FondSizeQuestion {
  font-size: 36px;
}
.FondSizeQuestionTitle {
  font-size: 46px;
}
@media screen and (max-width: 1130px) {
  .FondSizeQuestion {
    font-size: 30px;
  }
}

@media screen and (max-width: 960px) {
  .FondSizeQuestion {
    font-size: 25px;
  }
}

@media screen and (max-width: 820px) {
  .FondSizeQuestion {
    font-size: 18px;
  }
}

@media screen and (max-width: 1380px) {
  .FondSizeQuestionTitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .FondSizeQuestionTitle {
    font-size: 38px;
  }
}

@media screen and (max-width: 1150px) {
  .FondSizeQuestionTitle {
    font-size: 33px;
  }
}
@media screen and (max-width: 960px) {
  .FondSizeQuestionTitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 820px) {
  .FondSizeQuestionTitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 799px) {
  .FondSizeQuestionTitle {
    font-size: 16px;
    left: 12.5% !important;
    top: 15.6% !important;
  }
}

@media screen and (max-width: 799px) {
  .FondSizeQuestion {
    font-size: 12px;
  }

  input.largerCheckbox {
    transform: scale(1) !important;
    margin-right: 5px !important;
    margin-left: 2px !important;
    height: 11px !important;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 3.25%; /* Player ratio: 100 / (1280 / 720) */
  background-color: black;
}
Player {
  position: absolute;
  top: 0;
  left: 0;
}

input.largerCheckbox {
  transform: scale(2);
  margin-right: 25px;
  margin-left: 10px;
  height: 30px;
}
